import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

import { Header, Container, TextBlock, LinkButton } from "../../UI/Common"

import {
  headerCls,
  textAreaWrapperCls,
  textAreaCls,
  headingCls,
  descriptionCls,
  imageWrapperCls,
} from "./HomeBannerVariant2.module.scss"
import { OrgStatsDesktop, OrgStatsMobile } from "./OrgStats"
import Text from "../../UI/Text/Text"
const HomeBannerVariant2 = ({
  googleReviewData,
  pageTitle,
  pageTitleType,
  description,
  backgroundImg,
  stats,
  button,
  config: { padding },
}) => {
  const {
    googlePlacesPlace: { rating },
  } = googleReviewData
  const { desktop: desktopImg, mobile: mobileImg } = backgroundImg

  let bothImagesPresent = false
  if (desktopImg && mobileImg) {
    bothImagesPresent = true
  }

  return (
    <Header>
      <Container className={headerCls} padding={padding} fluid>
        <>
          <div className={imageWrapperCls}>
            {bothImagesPresent && (
              <>
                <GatsbyImage
                  className="d-none d-lg-block h-100 w-100"
                  alt={desktopImg.altText}
                  image={getImage(desktopImg.localFile)}
                />
                <GatsbyImage
                  className="d-lg-none h-100 w-100"
                  alt={mobileImg.altText}
                  image={getImage(mobileImg.localFile)}
                />
              </>
            )}
          </div>

          <Container className="position-relative pb-4">
            <>
              <div className={textAreaWrapperCls}>
                <div className={textAreaCls}>
                  <Text textCls={headingCls} text={pageTitle} type={pageTitleType} />
                  <TextBlock className={descriptionCls} text={description} />
                  <LinkButton
                    link={button?.url}
                    title={button?.title}
                    target={button?.target}
                  />
                </div>
              </div>

              <OrgStatsDesktop stats={stats} rating={rating} />
            </>
          </Container>
        </>
      </Container>

      <OrgStatsMobile stats={stats} rating={rating} />
    </Header>
  )
}

export default HomeBannerVariant2
