import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col } from "react-bootstrap"

import {
  statsMobilePositionCls,
  ratingBoxMobileCls,
  statsDesktopPositionCls,
  statsWrapperCls,
  statBoxCls,
  ratingBoxCls,
  statCls,
  statLableCls,
  starsCls,
} from "./OrgStats.module.scss"

export const OrgStatsMobile = ({ stats, rating }) => {
  return (
    <div className={statsMobilePositionCls}>
      <div className={ratingBoxMobileCls}>
        <div>
          <span className={statCls}>{rating}</span>
          <span
            className={starsCls}
            aria-label={`Rated ${rating} out of 5.`}></span>

          <div className={statLableCls}>Customer reviews</div>
        </div>
      </div>
    </div>
  )
}

export const OrgStatsDesktop = ({ stats, rating }) => {
  return (
    <div className={statsDesktopPositionCls}>
      <div className={statsWrapperCls}>
        <Row>
          {React.Children.toArray(
            stats.map(item => (
              <Col xs={12} md={4}>
                <div className={statBoxCls}>
                  <div className={statCls}>{item.stat}</div>
                  <div className={statLableCls}>{item.label}</div>
                </div>
              </Col>
            ))
          )}
          <Col xs={12} md={4}>
            <div className={ratingBoxCls}>
              <div>
                <span className={statCls}>{rating}</span>
                <span
                  className={starsCls}
                  aria-label={`Rated ${rating} out of 5.`}></span>
              </div>
              <div className={statLableCls}>Customer reviews</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
