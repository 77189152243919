// extracted by mini-css-extract-plugin
export var bgGrey100 = "HomeBannerVariant2-module--bg-grey-100--7aa73";
export var bgGrey150 = "HomeBannerVariant2-module--bg-grey-150--504ac";
export var bgGrey200 = "HomeBannerVariant2-module--bg-grey-200--ff7f6";
export var bgGrey300 = "HomeBannerVariant2-module--bg-grey-300--d26f5";
export var bgGrey400 = "HomeBannerVariant2-module--bg-grey-400--e7e21";
export var bgGrey500 = "HomeBannerVariant2-module--bg-grey-500--a3a7f";
export var bgGrey600 = "HomeBannerVariant2-module--bg-grey-600--73ae9";
export var bgGrey700 = "HomeBannerVariant2-module--bg-grey-700--f80fd";
export var bgGrey800 = "HomeBannerVariant2-module--bg-grey-800--91135";
export var bgGrey900 = "HomeBannerVariant2-module--bg-grey-900--3e1e3";
export var buttonsCls = "HomeBannerVariant2-module--buttonsCls--8bc1c";
export var descriptionCls = "HomeBannerVariant2-module--descriptionCls--73ef0";
export var headerCls = "HomeBannerVariant2-module--headerCls--5e043";
export var headingCls = "HomeBannerVariant2-module--headingCls--62823";
export var imageWrapperCls = "HomeBannerVariant2-module--imageWrapperCls--8d27b";
export var textAreaCls = "HomeBannerVariant2-module--textAreaCls--e1fda";
export var textAreaWrapperCls = "HomeBannerVariant2-module--textAreaWrapperCls--30647";
export var textGrey100 = "HomeBannerVariant2-module--text-grey-100--a2487";
export var textGrey150 = "HomeBannerVariant2-module--text-grey-150--5e4d2";
export var textGrey200 = "HomeBannerVariant2-module--text-grey-200--17f88";
export var textGrey300 = "HomeBannerVariant2-module--text-grey-300--814d6";
export var textGrey400 = "HomeBannerVariant2-module--text-grey-400--db43c";
export var textGrey500 = "HomeBannerVariant2-module--text-grey-500--c3b86";
export var textGrey600 = "HomeBannerVariant2-module--text-grey-600--c1a6e";
export var textGrey700 = "HomeBannerVariant2-module--text-grey-700--64229";
export var textGrey800 = "HomeBannerVariant2-module--text-grey-800--9ef15";
export var textGrey900 = "HomeBannerVariant2-module--text-grey-900--934d9";