import React from "react"
import * as PropTypes from "prop-types"

const Text = ({ textWrapperCls="",textCls="", text, type="h1" }) => {
  return (text && (
    <div
			className={textWrapperCls}
      dangerouslySetInnerHTML={{
        __html: `<${type} class="${textCls}">${text}</${type}>`
      }}
    />
  ))
}

Text.propTypes = {
  text: PropTypes.string,
  textCls: PropTypes.string,
  textWrapperCls: PropTypes.string,
  type: PropTypes.string
}

export default Text
