// extracted by mini-css-extract-plugin
export var bgGrey100 = "OrgStats-module--bg-grey-100--124f4";
export var bgGrey150 = "OrgStats-module--bg-grey-150--7639c";
export var bgGrey200 = "OrgStats-module--bg-grey-200--1041d";
export var bgGrey300 = "OrgStats-module--bg-grey-300--e8048";
export var bgGrey400 = "OrgStats-module--bg-grey-400--fb68f";
export var bgGrey500 = "OrgStats-module--bg-grey-500--455ec";
export var bgGrey600 = "OrgStats-module--bg-grey-600--5f5ab";
export var bgGrey700 = "OrgStats-module--bg-grey-700--1fc67";
export var bgGrey800 = "OrgStats-module--bg-grey-800--9c4f4";
export var bgGrey900 = "OrgStats-module--bg-grey-900--dcc4b";
export var ratingBoxCls = "OrgStats-module--ratingBoxCls--becaa";
export var ratingBoxMobileCls = "OrgStats-module--ratingBoxMobileCls--6929e";
export var starsCls = "OrgStats-module--starsCls--87ea2";
export var statBoxCls = "OrgStats-module--statBoxCls--90081";
export var statCls = "OrgStats-module--statCls--7bf69";
export var statLableCls = "OrgStats-module--statLableCls--22575";
export var statsDesktopPositionCls = "OrgStats-module--statsDesktopPositionCls--dfc5e";
export var statsMobilePositionCls = "OrgStats-module--statsMobilePositionCls--190a5";
export var statsWrapperCls = "OrgStats-module--statsWrapperCls--fac28";
export var textGrey100 = "OrgStats-module--text-grey-100--dc836";
export var textGrey150 = "OrgStats-module--text-grey-150--ce069";
export var textGrey200 = "OrgStats-module--text-grey-200--cd172";
export var textGrey300 = "OrgStats-module--text-grey-300--72dbf";
export var textGrey400 = "OrgStats-module--text-grey-400--31a9c";
export var textGrey500 = "OrgStats-module--text-grey-500--f455d";
export var textGrey600 = "OrgStats-module--text-grey-600--b5e50";
export var textGrey700 = "OrgStats-module--text-grey-700--092a8";
export var textGrey800 = "OrgStats-module--text-grey-800--ec032";
export var textGrey900 = "OrgStats-module--text-grey-900--8cdd0";