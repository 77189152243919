import { graphql } from "gatsby"
import React from "react"

import HomeBannerVariant2Component from "./HomeBannerVariant2"

export const fragment = graphql`
  fragment HomeBannerVariant2Fragment on WpPage_Flexlayouts_FlexibleLayouts_HomeBannerVariant2 {
    backgroundImg {
      desktop {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 2117
              placeholder: NONE
            )
          }
        }
      }
      mobile {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              height: 960
              placeholder: NONE
            )
          }
        }
      }
    }
    pageTitle
    pageTitleType
    description
    button {
      url
      title
      target
    }
    stats {
      stat
      label
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

const ACFHomeBannerVariant2 = ({
  googleReviewData,
  pageTitle,
  pageTitleType,
  description,
  backgroundImg,
  button,
  stats,
  config,
}) => {
  return (
    <HomeBannerVariant2Component
      googleReviewData={googleReviewData}
      backgroundImg={backgroundImg}
      pageTitle={pageTitle}
      pageTitleType={pageTitleType}
      description={description}
      button={button}
      stats={stats}
      config={config}
    />
  )
}

export default ACFHomeBannerVariant2
